@import "Variables.scss";

.Contact {
    width: calc(100% - 80px);
    margin: 0 auto 0 auto;
}

.Contact a {
    color: #3381e3;
    font-weight: 700;
    text-decoration: none;
}

.ContactLogo {
    display: block;
    width: 160px;
    height: 160px;
    box-shadow: inset 0px 0px 10px 0px #78589dd4;
    border-radius: 50%;
  
    display: flex;
    justify-content: center;
    align-items: center;
  
    margin: 5em auto 0 auto;
}
  
.ContactLogoIcon {
    width: 90%;
    height: 90%;
    background: #cdcae900;
    box-shadow: inset 0px 0px 20px 0px #4a1285;
    border-radius: inherit;
    backdrop-filter: blur( $app-blurred );
    -webkit-backdrop-filter: blur( $app-blurred );
  
    display: flex;
    justify-content: center;
    align-items: center;
  
    font-size: 45px;
    color: #2e1f5c;
}
