@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Roboto:wght@300;500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Oswald:wght@300;400;500;600;700&family=Roboto:wght@300;500;700&display=swap');

@import 'Variables.scss';
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  width: 100%;
  height: 100%;
}

#root {
  position: fixed;
  width: 100vw;
  height: 100%;
  background: center / cover no-repeat url('./background.png');

  display: flex;
  justify-content: center;
  align-items: center;

  color: #443d58;
  overflow: scroll;
}

.App {
  width: calc(100% - #{$app-margin});
  height: calc(100% - #{$app-margin});

  min-width: 300px;
  max-width: 600px;

  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur( $app-blurred );
  -webkit-backdrop-filter: blur( $app-blurred );
  border: $border;
  border-radius: 30px;
  
  position: relative;
}

.AppContent {
  max-height: calc(100% - 100px);
  overflow-y: scroll;
}

h1 {
  text-align: center;
  font: normal 700 1.9em Oswald, Helvetica, Arial, sans-serif;

  margin: 0;
  padding: 0.5em 0;
}

h2 {
  font: normal 500 1.35em Oswald, Arial, sans-serif;
  margin: 0;
}

section {
  height: inherit;
}

p {
  text-align: justify;
  font: 300 0.9em 'Roboto';
  line-height: 1.5em;
}

@media only screen and (min-width: 600px) {
  .App {
    height: 700px;
  }
}
