
@import "Variables.scss";

.Home {
    width: 100%;
    height: 100%;

    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    padding: 40px;
}

.LogoImage {
    height: 160px;
    filter: invert(0.85);
}

.LogoText {
    font: small-caps 700 1.2em Montserrat, Helvetica, Arial, sans-serif;
    text-transform: lowercase;
    letter-spacing: 10px;
    margin: 1.2em auto 1em auto;
}

.ProjectNavLink {
    background: $accent-color;
    color: white;
    border: none;
    border-radius: 1.5em;

    font: small-caps 500 0.9em Montserrat, Helvetica, Arial, sans-serif;
    text-transform: lowercase;
    text-decoration: none;
    letter-spacing: 2px;

    width: 190px;
    height: 37px;
    margin-top: 1em;
  
    display: flex;
    justify-content: center;
    align-items: center;
}
  