@import "Variables.scss";

$carousel-item-background-margin: 10px;
$text-width: 80%;

.Project {
    padding-top: 1em;
    height: 100%;
}

.Project h1 {
    display: flex;
    justify-content: space-between;
    align-items: center;

    width: $text-width;
    border-bottom: $border;
    padding-bottom: 1em;
    margin: 0 auto;

    font-weight: 500;
    font-size: 1.8em;
    letter-spacing: normal;
}

.Project p {
    width: $text-width;
    margin: 1em auto;
}

.ProjectContent {
    height: calc(100% - 180px);
    border-bottom: $border;
    padding: 1em 0;
    overflow-y: scroll;
}

.CarouselItem {
    position: relative;
    width: $text-width;
    aspect-ratio: 16 / 9;
    overflow: hidden;
    margin: 0 7px;

    background: contain / center no-repeat rgba(255, 255, 255, 0.4);
    border-radius: 10px;

    opacity: 0.5;
    backdrop-filter: blur( 10px );

    display: flex;
    justify-content: center;
    align-items: center;
}

.CarouselItemBackground {
    z-index: -1;
    background-size: cover;

    position: absolute;
    top: -$carousel-item-background-margin;
    right: -$carousel-item-background-margin;
    bottom: -$carousel-item-background-margin;
    left: -$carousel-item-background-margin;

    filter: blur($carousel-item-background-margin);
    -webkit-filter: blur($carousel-item-background-margin);

    clip-path: inset($carousel-item-background-margin);
    -webkit-clip-path: inset($carousel-item-background-margin);
}

.CarouselMediaItem {
    width: 100%;
    height: 100%;

    background: inherit;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}

.CarouselItem.is-selected {
    opacity: 1;
}

.BackNavLink {
    width: 42px;
    height: 42px;

    margin: 0;
}

