@import "Variables.scss";

$project-item-margin: 30px;
$project-item-preview-margin: 10px;

.ProjectsList {
    height: calc(100% - 130px);
    overflow-y: scroll;
    border-bottom: $border;
}

.ProjectItem {
    display: flex;
    width: calc(100% - 2 * #{$project-item-margin});
    height: 100px;

    border-bottom: $border;
    margin: 0 auto;
    padding-top: 1em;

    color: inherit;
    text-decoration: none;
    
    overflow: hidden;
}

.ProjectItem:first-child {
    border-top: $border;
}

.ProjectItemPreview {
    width: 70px;
    height: 70px;

    margin-right: 20px;
    border-radius: 12px;
    
    box-shadow: inset 0px 0px 7px 0px #75628bd4;

    flex-shrink: 0;

    display: flex;
    justify-content: center;
    align-items: center;
}

.ProjectItemPreviewIcon {
    width: calc(100% - #{$project-item-preview-margin});
    height: calc(100% - #{$project-item-preview-margin});

    border-radius: 10px;
    
    background-size: cover;
    background-repeat: no-repeat;

    box-shadow: inset 0px 0px 7px 0px #51475cd4;
}

@media only screen and (max-width: 400px) {
    .ProjectItem {
      height: 120px;
    }
}

@media only screen and (min-width: 600px) {
    .ProjectsList {
        height: calc(100% - 175px);
    }
}
  