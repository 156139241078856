@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Roboto:wght@300;500;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Oswald:wght@300;400;500;600;700&family=Roboto:wght@300;500;700&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

*{margin:0;padding:0;box-sizing:border-box}body{width:100%;height:100%}#root{position:fixed;width:100vw;height:100%;background:center/cover no-repeat url(/static/media/background.e56399ca.png);display:flex;justify-content:center;align-items:center;color:#443d58;overflow:scroll}.App{width:calc(100% - 40px);height:calc(100% - 40px);min-width:300px;max-width:600px;background:rgba(255,255,255,0.1);backdrop-filter:blur(20px);-webkit-backdrop-filter:blur(20px);border:1px solid rgba(255,255,255,0.5);border-radius:30px;position:relative}.AppContent{max-height:calc(100% - 100px);overflow-y:scroll}h1{text-align:center;font:normal 700 1.9em Oswald, Helvetica, Arial, sans-serif;margin:0;padding:0.5em 0}h2{font:normal 500 1.35em Oswald, Arial, sans-serif;margin:0}section{height:inherit}p{text-align:justify;font:300 0.9em 'Roboto';line-height:1.5em}@media only screen and (min-width: 600px){.App{height:700px}}

.Menu{display:flex;justify-content:center;align-items:center;width:100%;position:absolute;bottom:0;left:0}.MenuItem{width:60px;height:60px;flex-shrink:0;border:1px solid rgba(255,255,255,0.5);border-radius:50%;display:flex;justify-content:center;align-items:center;box-shadow:inset 0px 0px 3px 0px #00000073;margin:20px 30px}.MenuItemIcon{font:inherit;color:#ddcee5;border:none;background:white;width:80%;height:80%;border-radius:inherit;display:inherit;justify-content:inherit;align-items:inherit;box-shadow:0px 0px 4px 0px #ffffff8c}.MenuItem.active .MenuItemIcon{background:#885cc5;color:white}

.Home{width:100%;height:100%;display:flex;justify-content:center;align-items:center;flex-direction:column;padding:40px}.LogoImage{height:160px;filter:invert(0.85)}.LogoText{font:small-caps 700 1.2em Montserrat, Helvetica, Arial, sans-serif;text-transform:lowercase;letter-spacing:10px;margin:1.2em auto 1em auto}.ProjectNavLink{background:#885cc5;color:white;border:none;border-radius:1.5em;font:small-caps 500 0.9em Montserrat, Helvetica, Arial, sans-serif;text-transform:lowercase;text-decoration:none;letter-spacing:2px;width:190px;height:37px;margin-top:1em;display:flex;justify-content:center;align-items:center}

.ProjectsList{height:calc(100% - 130px);overflow-y:scroll;border-bottom:1px solid rgba(255,255,255,0.5)}.ProjectItem{display:flex;width:calc(100% - 2 * 30px);height:100px;border-bottom:1px solid rgba(255,255,255,0.5);margin:0 auto;padding-top:1em;color:inherit;text-decoration:none;overflow:hidden}.ProjectItem:first-child{border-top:1px solid rgba(255,255,255,0.5)}.ProjectItemPreview{width:70px;height:70px;margin-right:20px;border-radius:12px;box-shadow:inset 0px 0px 7px 0px #75628bd4;flex-shrink:0;display:flex;justify-content:center;align-items:center}.ProjectItemPreviewIcon{width:calc(100% - 10px);height:calc(100% - 10px);border-radius:10px;background-size:cover;background-repeat:no-repeat;box-shadow:inset 0px 0px 7px 0px #51475cd4}@media only screen and (max-width: 400px){.ProjectItem{height:120px}}@media only screen and (min-width: 600px){.ProjectsList{height:calc(100% - 175px)}}

.Contact{width:calc(100% - 80px);margin:0 auto 0 auto}.Contact a{color:#3381e3;font-weight:700;text-decoration:none}.ContactLogo{display:block;width:160px;height:160px;box-shadow:inset 0px 0px 10px 0px #78589dd4;border-radius:50%;display:flex;justify-content:center;align-items:center;margin:5em auto 0 auto}.ContactLogoIcon{width:90%;height:90%;background:#cdcae900;box-shadow:inset 0px 0px 20px 0px #4a1285;border-radius:inherit;backdrop-filter:blur(20px);-webkit-backdrop-filter:blur(20px);display:flex;justify-content:center;align-items:center;font-size:45px;color:#2e1f5c}

.Project{padding-top:1em;height:100%}.Project h1{display:flex;justify-content:space-between;align-items:center;width:80%;border-bottom:1px solid rgba(255,255,255,0.5);padding-bottom:1em;margin:0 auto;font-weight:500;font-size:1.8em;letter-spacing:normal}.Project p{width:80%;margin:1em auto}.ProjectContent{height:calc(100% - 180px);border-bottom:1px solid rgba(255,255,255,0.5);padding:1em 0;overflow-y:scroll}.CarouselItem{position:relative;width:80%;aspect-ratio:16 / 9;overflow:hidden;margin:0 7px;background:contain/center no-repeat rgba(255,255,255,0.4);border-radius:10px;opacity:0.5;-webkit-backdrop-filter:blur(10px);backdrop-filter:blur(10px);display:flex;justify-content:center;align-items:center}.CarouselItemBackground{z-index:-1;background-size:cover;position:absolute;top:-10px;right:-10px;bottom:-10px;left:-10px;filter:blur(10px);-webkit-filter:blur(10px);clip-path:inset(10px);-webkit-clip-path:inset(10px)}.CarouselMediaItem{width:100%;height:100%;background:inherit;background-size:contain;background-repeat:no-repeat;background-position:center}.CarouselItem.is-selected{opacity:1}.BackNavLink{width:42px;height:42px;margin:0}

/*! Flickity v2.1.2
https://flickity.metafizzy.co
---------------------------------------------- */

.flickity-enabled {
    position: relative;
  }
  
  .flickity-enabled:focus {
    outline: none;
  }
  
  .flickity-viewport {
    overflow: hidden;
    position: relative;
    height: 100%;
  }
  
  .flickity-slider {
    position: absolute;
    width: 100%;
    height: 100%;
  }
  
  /* draggable */
  
  .flickity-enabled.is-draggable {
    -webkit-tap-highlight-color: transparent;
    tap-highlight-color: transparent;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  
  .flickity-enabled.is-draggable .flickity-viewport {
    cursor: move;
    cursor: grab;
  }
  
  .flickity-enabled.is-draggable .flickity-viewport.is-pointer-down {
    cursor: grabbing;
  }
  
  /* ---- flickity-button ---- */
  
  .flickity-button {
    visibility: hidden;
    position: absolute;
    background: hsla(0, 0%, 100%, 0.75);
    border: none;
    color: #333;
  }
  
  .flickity-button:hover {
    background: white;
    cursor: pointer;
  }
  
  .flickity-button:focus {
    outline: none;
    box-shadow: 0 0 0 5px #19f;
  }
  
  .flickity-button:active {
    opacity: 0.6;
  }
  
  .flickity-button:disabled {
    opacity: 0.3;
    cursor: auto;
    /* prevent disabled button from capturing pointer up event. #716 */
    pointer-events: none;
  }
  
  .flickity-button-icon {
    fill: #333;
  }
  
  /* ---- previous/next buttons ---- */
  
  .flickity-prev-next-button {
    top: 50%;
    width: 44px;
    height: 44px;
    border-radius: 50%;
    /* vertically center */
    transform: translateY(-50%);
  }
  
  .flickity-prev-next-button.previous {
    left: 10px;
  }
  .flickity-prev-next-button.next {
    right: 10px;
  }
  /* right to left */
  .flickity-rtl .flickity-prev-next-button.previous {
    left: auto;
    right: 10px;
  }
  .flickity-rtl .flickity-prev-next-button.next {
    right: auto;
    left: 10px;
  }
  
  .flickity-prev-next-button .flickity-button-icon {
    position: absolute;
    left: 20%;
    top: 20%;
    width: 60%;
    height: 60%;
  }
  
  /* ---- page dots ---- */
  
  .flickity-page-dots {
    position: absolute;
    width: 100%;
    bottom: -15px;
    padding: 0;
    margin: 0;
    list-style: none;
    text-align: center;
    line-height: 1;
  }
  
  .flickity-rtl .flickity-page-dots {
    direction: rtl;
  }
  
  .flickity-page-dots .dot {
    display: inline-block;
    width: 8px;
    height: 8px;
    margin: 0 5px;
    background: #333;
    border-radius: 50%;
    opacity: 0.25;
    cursor: pointer;
  }
  
  .flickity-page-dots .dot.is-selected {
    opacity: 1;
  }
  
