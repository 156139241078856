@import 'Variables.scss';

.Menu {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;

    position: absolute;
    bottom: 0;
    left: 0;
}

.MenuItem {
    width: 60px;
    height: 60px;
    flex-shrink: 0;
  
    border: $border;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
  
    box-shadow: inset 0px 0px 3px 0px #00000073;
    margin: 20px 30px;
}
  
.MenuItemIcon {
    font: inherit;
    color: #ddcee5;
    border: none;
    background: white;
  
    width: 80%;
    height: 80%;
  
    border-radius: inherit;
    display: inherit;
    justify-content: inherit;
    align-items: inherit;
    box-shadow: 0px 0px 4px 0px #ffffff8c;
}
  
.MenuItem.active .MenuItemIcon{
    background: $accent-color;
    color: white;
}
